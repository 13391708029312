'use client'

import { BodyObjectView/*, key*/ } from 'utility/utility'
import { ComponentBodyPhotoGalleryFragment } from 'generated/graphql'
//import { Markdown } from 'components/shared/Markdown/Markdown'
//import { SectionHeader } from 'components/shared/SectionHeader/SectionHeader'
//import styles from './ComponentBodyPhotoGallery.module.scss'
//import ImageGallery from 'react-image-gallery'
//import { ReactImageGalleryItem } from 'react-image-gallery'
//import "react-image-gallery/styles/css/image-gallery.css"

export const ComponentBodyPhotoGallery: BodyObjectView<ComponentBodyPhotoGalleryFragment> =
    (/*{
        images,
        header,
        siteContext,
        articleContext,
        pageContext,
        ...object
    }*/) => {

        return <></>

//         const galleryItems: ReactImageGalleryItem[] = images.map(image => {
//             return {
//                 original: image.image.data?.attributes?.url ?? '',
//                 thumbnail: image.image.data?.attributes?.url ?? ''
//             }
//         })
//
//         return (
//             <section
//                 id={key(object)}
//             >
//                 {
//                     header === null || header === '' ? <></> :
//                         <SectionHeader className={styles.Header}>
//                             <Markdown
//                                 siteContext={siteContext}
//                                 pageContext={pageContext}
//                                 articleContext={articleContext}
//                             >
//                                 {header}
//                             </Markdown>
//                         </SectionHeader>
//                 }
//                 <section className={styles.Images}>
//                 {
//                     images === null || images.length === 0 ? <></> :
//                         <ImageGallery
//                             items={galleryItems}
//                             additionalClass={styles.Gallery}
//                         />
//                 }
//                 </section>
//             </section>
//         )

    }

