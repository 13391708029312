'use client'

import { Suspense } from 'react'
import { BodyObjectView, key } from 'utility/utility'
import { ComponentBodySessionBrowserFragment } from 'generated/graphql'
import { useSearchParams } from 'next/navigation'

import { SessionFeatured } from 'content-types/Session/Session.Featured/Session.Featured'
import { Theme } from 'content-types/Theme/Theme'
import { TheatreFilters } from './TheatreFilters'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { SectionHeader } from 'components/shared/SectionHeader/SectionHeader'

import styles from './ComponentBodySessionBrowser.module.scss'

export const ComponentBodySessionBrowser: BodyObjectView<ComponentBodySessionBrowserFragment> =
    ({
        text,
        theme,
        event,
        siteContext,
        pageContext,
        articleContext,
        ...object
    }) => {

        const searchParams = useSearchParams()

        const taggedSessions = event?.data?.attributes?.sessions?.data ?? []
        const parentEventSessions = event?.data?.attributes?.parent_event?.data?.attributes?.sessions?.data ?? []
        const childEventsSessions = event?.data?.attributes?.child_events?.data?.map(event => event.attributes?.sessions?.data) ?? []
        const siblingEventsSessions = event?.data?.attributes?.parent_event?.data?.attributes?.child_events?.data?.map(event => event.attributes?.sessions?.data) ?? []

        const sessions = [
            ...taggedSessions.map( ({attributes}) => attributes ),
            ...parentEventSessions.map( ({attributes}) => attributes ),
            ...childEventsSessions.flat().map( session => session?.attributes ),
            ...siblingEventsSessions.flat().map( session => session?.attributes )
        ]

        const theatres = [...new Set(sessions.map(session => session?.theatre?.data?.attributes?.title))]

        const days = [...new Set(sessions.map(session => session?.date))]

        return (
            <section
                id={key(object)}
                className={styles.SessionBrowser}
                style={ Theme(theme?.data?.attributes) }
            >
                {
                    text === null || text === '' ? <></> :
                        <SectionHeader>
                            <Markdown
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={articleContext}
                            >
                                {text}
                            </Markdown>
                        </SectionHeader>
                }
                {theatres.length > 1 ? <Suspense><TheatreFilters theatres={theatres} active={ searchParams.get("theatre")}/></Suspense>: <></>}
                <section data-days={days.length} className={styles.Columns}>
                {days.map ( day =>
                    <section key={day} className={styles.Day}>
                        <h1> Day {days.indexOf(day) + 1} </h1>
                        <h1>
                            {day === null || day === undefined ? <></> :
                            new Date(day).toLocaleString('default', { month: 'long' }) + " "
                            + new Date(day).getDay() + ", " + new Date(day).getFullYear()}
                        </h1>
                        <ul data-number={sessions.length} className={styles.Sessions}>
                            {
                                sessions.filter(session => session?.date === day).sort((a, b) =>
                                    (a?.start === null || a?.start === undefined ? "" : a.start).localeCompare(b?.start === null || b?.start === undefined ? "" : b.start)
                                ).map( session =>
                                        session === null || session === undefined ||
                                        (searchParams.get("theatre") !== null && session.theatre?.data?.attributes?.title
                                            !== searchParams.get("theatre")) ? <></> :
                                            <li key={session.name}>
                                                <SessionFeatured
                                                    {...session}
                                                />
                                            </li>
                                    )
                            }
                        </ul>
                    </section>
                )}
                </section>
            </section>
        )

    }
