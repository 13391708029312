'use client'

import { FC } from 'react'

import { Accordion, AccordionItem } from '@szhsin/react-accordion'
import { type BodyObjectView } from 'utility/utility'
import { Markdown } from 'components/shared/Markdown/Markdown'


import './AccordionStyles.scss'
import ChevronDown from 'public/icons/chevron-down.svg'

const blockHeader: FC<string> = (title) =>
    <>
        {title}
        <ChevronDown className="chevron-down"/>
    </>


export const AccordionBlock: BodyObjectView<{blocks: {title?: string, content?: string}[]}> = ({
    blocks,
    ...context
}) =>
    <Accordion>
    {
        blocks.map(block =>
            <AccordionItem key={block.title ?? block.content} header={blockHeader(block.title ?? "test")}>
                <Markdown {...context}>{block.content ?? ''}</Markdown>
            </AccordionItem>
        )
    }
    </Accordion>
